// React
import React, {
    useEffect
} from 'react';

// Gatsby
import {
    Link,
    graphql
} from "gatsby"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Styles
import "../styles/pages/BlogIndexPage.scss"

// Nested components (must ome after the styles for this component)
import PostCard from '../components/PostCard';

// Blog index page component
// (Lists all posts)
const BlogIndex = ({
    data,
    location
}) => {

    // Page frontmatter
    const pageTitle = 'Blog posts';
    const pageCategory = 'Game Development';
    const pageDescription = 'Articles about game development.';

    // List of all blog posts
    const posts = data.allMdx.edges;

    // Resize generated "thumbnails" for blog posts
    // that don't have their own image file yet
    useEffect(() => {

        // Callback function
        const handleResize = () => {

            let thumbElems = document.getElementsByClassName('thumbnail-none');

            for (var i = 0; i < thumbElems.length; i++) {

                let thumbElem = thumbElems.item(i);

                let thumbWidth = thumbElem.offsetWidth;
                let thumbHeight = thumbWidth / 1.9048 + 'px';

                thumbElem.style.height = thumbHeight;
                thumbElem.style.maxHeight = thumbHeight;
            }
        }

        // On first load
        handleResize();

        // Initiate the window resize event handler
        window.addEventListener('resize', handleResize, false);

        // This will clean up the event every time the component is re-rendered
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
            />

            {/* Page */}
            <article id="blog-index-page">

                {/* Page header */}
                <header className="page-header">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>

                    {/* Buttons on top-right */}
                    <aside className="page-buttons">
                        <Link to={'/tags'}>
                            Topics
                        </Link>
                    </aside>

                </header>

                {/* Page content */}
                <main className="page-main">

                    {/* Posts list */}
                    <div className="list-posts">
                        {posts.map(({ node: post }) => (

                            // Post card
                            <PostCard
                                post={post}
                                key={post.fields.slug}
                            />

                        ))}
                    </div>

                </main>

            </article>

        </Layout>
    )
}

// Export component
export default BlogIndex

// GraphQL query
export const pageQuery = graphql`

  query BlogPosts{

    allMdx(
        filter: { 
            fileAbsolutePath: {glob: "**/content/blog/**/**.mdx" },
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 1000
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            category
            date(formatString: "MMM D, YYYY")
            description
            tags
            #image {
                # childImageSharp {
                    #fluid(maxWidth: 800) {
                        #...GatsbyImageSharpFluid
                        #}
                        #}
            #}            
          }
        }
      }
    }
    
  }
`
